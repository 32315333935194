<template>
  <div>
    <v-container>
      <v-row>


        <v-col cols="12" sm="8" md="8">
          <v-chip
              class="ma-2"
              x-large
              color="#455A64"
              style="padding-right: 50px;padding-left: 50px"
          >
            <h1 style="color: white">{{$t('rpitsbLeader')}}</h1>
          </v-chip>
          <v-timeline :reverse="!$vuetify.breakpoint.smAndDown" :dense="$vuetify.breakpoint.smAndDown">
          <v-timeline-item
              v-for="(d,i) in infoList"
              :key="i"
              dark
              large
          >
            <template v-slot:icon>
              <v-avatar :size="!$vuetify.breakpoint.smAndDown ?168 : 140">
                <img v-if="d.url" :src="d.url">
              </v-avatar>
            </template>
            <template v-slot:opposite>
              <span style="font-family: 'Khmer OS Muol Light';margin: 50px">{{ $t(d.name) }}</span>
            </template>
            <v-card class="elevation-2" :style="i%2==0 ? ($vuetify.breakpoint.smAndDown ? 'margin-left: 50px;background-color:#952175;' : 'margin-right:50px;background-color:#1F7087;')  : ($vuetify.breakpoint.smAndDown ? 'margin-left: 50px;background-color:#1F7087;' : 'margin-left:50px;background-color:#952175;')">
              <v-card-title class="text-h5">
                <span style="font-family: 'Khmer OS Muol Light'">{{$t(d.position)}}</span>

              </v-card-title>
              <span v-if="$vuetify.breakpoint.smAndDown" style="font-family: 'Khmer OS Muol Light';margin: 12px">{{ $t(d.name) }}</span>

              <v-divider inset style="color: green"></v-divider>

              <v-card-text style="text-align: left">
                <p v-if="d.phoneNumber"><b>{{$t('phoneNumber')}} :</b> {{d.phoneNumber}}</p>
                <p v-if="d.email"><b>{{$t('email')}} :</b> {{d.email}}</p>
                <p v-if="d.phd"><b>{{$t('phd')}} :</b> {{$t(d.phd)}}</p>
                <p v-if="d.master"><b>{{$t('master')}} :</b> {{$t(d.master)}}</p>
                <p v-if="d.bachelor"><b>{{$t('bachelor')}} :</b> {{$t(d.bachelor)}}</p>
                <p v-if="d.associate"><b>{{$t('associate')}} :</b> {{$t(d.associate)}}</p>

              </v-card-text>
            </v-card>
          </v-timeline-item>
          </v-timeline>
        </v-col>

        <v-col cols="12" sm="4" md="4" v-if="!$vuetify.breakpoint.mobile">
          <side-bar-event></side-bar-event>
          <media></media>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SideBarEvent from "@/components/sidebarEvent";
import Media from "@/components/sidebarMedia";

export default {
  name: 'leader',
  components: {Media,SideBarEvent},
  data(){
    return{
        infoList:[
          {
            name:"chhim_thoeun",
            position:"director",
            phoneNumber:"+855-17 480 762",
            bachelor:"english",
            master:"management",
            phd:"",
            email:"thoeunchhim@yahoo.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/chhim_thoeurn.jpg?alt=media&token=0221f8d1-18ac-40cc-88c3-4ba93e19d449"
          },
          {
            name:"chhean_sarith",
            position:"deputyDirector",
            phoneNumber:"+855-69 54 54 64",
            bachelor:"agricultural_economic_and_rural_development",
            master:"",
            email:"sarithchhean222@gmail.com",
            phd:"",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2FIMG_20210909_091403_257%20-%20Sarith%20Chh.jpg?alt=media&token=c60eddbc-aac6-4165-b972-d77752679e0f"
          },
          {
            name:"sanh_sopheap",
            position:"deputyDirector1",
            phoneNumber:"+855-92 419 247",
            bachelor:"businessAdministration",
            master:"businessAdministration",
            phd:"",
            email:"sanhsopheap11@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2FF5DFDAEB-EBC3-4F94-8217-037F1E578C31%20-%20Sanh%20Sopheap.jpeg?alt=media&token=baf929e1-ed6e-4619-b6e9-799b6f419a1e"
          },
          {
            name:"kim_chanro",
            position:"deputyDirector",
            phoneNumber:"+855-12 829 077",
            bachelor:"english",
            master:"businessAdministration",
            phd:"",
            email:"kim.chanro2017@gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2FDSC_4494%20copy%20-%20Kim%20Chanro.jpg?alt=media&token=d6e14df6-8d33-451b-a3fe-b192b60e13f9"
          },
          {
            name:"siek_darith",
            position:"deputyDirector",
            phoneNumber:"+855-15 222 750",
            bachelor:"animal_science_and_veterinary_medicine",
            master:"integrated_management_for_agricultural_and_rural_development",
            phd:"agricultural_economic",
            email:"darith9398@yahoo.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2Fphoto_2021-09-08_15-35-15.jpg?alt=media&token=f3b76d5a-7da1-4076-8f1c-9210a9b3e6d7"
          },
          {
            name:"hin_la",
            position:"deputyDirector",
            phoneNumber:"+855-12 561 781",
            associate:"veterinary",
            bachelor:"education",
            master:"general_management",
            phd:"",
            email:"hinlamba@.gmail.com",
            url:"https://firebasestorage.googleapis.com/v0/b/rpitsb-5327c.appspot.com/o/staffphoto%2FUSER_SCOPED_TEMP_DATA_orca-image-1622331686619_6804552282515957614%20-%20Bros%20La.jpeg?alt=media&token=600dfe81-3b47-4ac4-b70d-623e89478b27"
          }
        ]

    }
  },
}
</script>
