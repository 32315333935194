<template>
  <div>
    <v-card
        max-width="400"
        class="mx-auto"
    >
      <v-app-bar
          dark
          color="#455A64"
      >
        <v-toolbar-title>{{ $t('media') }}</v-toolbar-title>
      </v-app-bar>
      <v-container>
        <v-row dense>
          <v-col
              v-for="(item, i) in items"
              :key="i"
              cols="12"
              @click="gotoDetail(item)"
              style="cursor: pointer"
          >
            <v-card
                :color="i%2===0 ? '#1F7087' : '#952175' "
                dark
            >
              <div class="d-flex flex-no-wrap justify-space-between">
                <div>
                  <v-avatar
                      class="ma-3"
                      size="125"
                      tile
                  >
                    <v-img :src="item.urlList[0]"></v-img>

                  </v-avatar>

                </div>
                <div>
                  <v-card-title
                      style="text-align: left;word-break: break-word"
                      class="text-h7" v-html="getTranslate(item.title).substr(0,40)">
                  </v-card-title>


                </div>

              </div>

              <v-card-subtitle style="text-align: justify" v-html="getTranslate(item.body).substr(0, 120)"></v-card-subtitle>
              <div style="font-size: 8px;color: yellow;text-align: right">{{ item.date | formatDate }}</div>

            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

  </div>
</template>
<script>
import MyMixin from "@/mixins/mixin"
import {Constants} from "@/libs/constant";
import axios from "axios";

export default {
  name: "Media",
  mixins: [MyMixin],

  data() {
    return {
      items: [],
    }
  },
  methods: {
    gotoDetail(doc) {
      this.$router.push({name: 'NewsEventsDetail', params: {doc: doc}});
    },
    fetchMedia() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getMedia;
      return new Promise((resolve, reject) => {
        axios.post(url, {branchId: Constants.branchId}, {headers: {token: Constants.token}}).then(
            res => {
              console.log(res.data.data);
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.items = res.data.data.slice(0, 7);
              }
            },
            error => {
              reject(error);
            }
        );
      });
    }
  },
  created() {
    this.fetchMedia();
  },

}

</script>