<template>
  <div>
    <v-card
        max-width="400"
        class="mx-auto"
    >
      <v-app-bar
          dark
          color="#455A64"
      >
        <v-toolbar-title>{{ $t('usefulLink') }}</v-toolbar-title>
      </v-app-bar>
      <v-container>
        <v-list-item two-line target="_blank" href="https://www.tvet-elearning.com">
          <v-list-item-content>
            <v-list-item-title>TVET-ELearning</v-list-item-title>
            <v-list-item-subtitle>https://www.tvet-elearning.com</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line target="_blank" href="https://www.bongthom.com">
          <v-list-item-content>
            <v-list-item-title>Website Find Job (Bongthom)</v-list-item-title>
            <v-list-item-subtitle>https://www.bongthom.com</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
        <v-list-item two-line target="_blank" href="https://www.camhr.com">
          <v-list-item-content>
            <v-list-item-title>Website Find Job (CAMHR)</v-list-item-title>
            <v-list-item-subtitle>https://www.camhr.com</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

      </v-container>
    </v-card>

    <v-card
        max-width="400"
        class="mx-auto"
    >
      <v-app-bar
          dark
          color="#455A64"
      >
        <v-toolbar-title>{{ $t('newsAndEvents') }}</v-toolbar-title>
      </v-app-bar>
      <v-container>
        <v-row dense>
          <v-col
              v-for="(item, i) in items"
              :key="i"
              cols="12"
              @click="gotoDetail(item)"
              style="cursor: pointer"
          >
            <v-card
                :color="i%2===0 ? '#1F7087' : '#952175' "
                dark
            >
              <div class="d-flex flex-no-wrap justify-space-between">
                <div class="hover02 columnH">
                  <div>
                    <figure>
                      <img width="100px;" height="130px" :src="item.url"/>
                    </figure>
                  </div>
                </div>
                <div>
                  <v-card-title
                      class="text-h7"
                      v-html="getTranslate(item.title).substr(0,20)"
                  ></v-card-title>
                  <v-card-subtitle style="text-align: left"
                                   v-html="getTranslate(item.body).substr(0,50)"></v-card-subtitle>
                  <div style="font-size: 8px;float: right;color: yellow">{{ item.date | formatDate }}</div>
                </div>
              </div>

            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>

  </div>
</template>
<script>
import MyMixin from "@/mixins/mixin"
import {Constants} from "@/libs/constant";
import axios from "axios";

export default {
  name: "SideBarEvent",
  mixins: [MyMixin],
  data() {
    return {
      items: []
    }
  },
  methods: {
    gotoDetail(doc) {
      this.$router.push({name: 'NewsEventsDetail', params: {doc: doc}});
    },
    fetchNewsAndEvent() {
      let vm = this;
      let url = Constants.baseURL + Constants.api.getNewsAndEvent;
      return new Promise((resolve, reject) => {
        axios.post(url, {branchId: Constants.branchId}, {headers: {token: Constants.token}}).then(
            res => {
              if (res.data.code === 201) {
                resolve(res.data.data);
                vm.items = res.data.data.slice(0, 7);
              }
            },
            error => {
              reject(error);
            }
        );
      });
    }
  },
  created() {
    this.fetchNewsAndEvent();
  }
}

</script>

